import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-wsp-follow",
  templateUrl: "./wsp-follow.component.html",
  styleUrls: ["./wsp-follow.component.scss"],
})
export class WspFollowComponent {
  @Input() phoneNumber: string = "541130247083";
  @Input() messageText: string = "";
  @Input() projectSlug?: string = "";

  constructor(private route: ActivatedRoute) {}

  encodeURIComponent(text: string): string {
    return encodeURIComponent(text);
  }
}
